import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Anchor,
  Box,
  Form,
  Button,
  TextInput
} from 'grommet'
import { ToastsStore } from 'react-toasts'

import firebase from 'firebase/app'

import * as Strings from 'common/strings'
import * as Validation from 'common/validation'

function handleLoginFormSubmit (email, password, history) {
  if (!Validation.validateEmail(email) ||
    !Validation.validatePassword(password)) {
    return null
  }

  firebase.auth().signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      history.push('/browse')
    })
    .catch((error) => {
      const errorCode = error.code
      if (errorCode === 'auth/user-not-found') {
        ToastsStore.info(Strings.userNotFound, 3000, 'burntToast')
      } else if (errorCode === 'auth/wrong-password') {
        ToastsStore.info(Strings.wrongPassword, 3000, 'burntToast')
      } else {
        ToastsStore.info(Strings.genericError, 3000, 'burntToast')
      }
    })
}

function handleForgotPasswordClick (email) {
  if (!Validation.validateEmail(email)) {
    return null
  }

  firebase.auth().sendPasswordResetEmail(email)
    .then(() => {
      ToastsStore.info(Strings.passwordResetEmailSent, 3000, 'burntToast')
    })
    .catch((error) => {
      const errorMessage = error.message
      ToastsStore.info(errorMessage, 3000, 'burntToast')
    })
}

const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()

  return (
    <Form onSubmit={
      () => handleLoginFormSubmit(email, password, history)
    }
    >
      <Box direction='column' gap='small'>
        <TextInput
          placeholder={Strings.email}
          value={email}
          onChange={e => setEmail(e.target.value)}
          className='textField'
        />
        <TextInput
          type='password'
          placeholder={Strings.password}
          value={password}
          onChange={e => setPassword(e.target.value)}
          className='textField'
        />
        <Button type='submit' primary label={Strings.login} />
        <Anchor
          alignSelf='center'
          onClick={() => handleForgotPasswordClick(email)}
        >
          {Strings.forgot}
        </Anchor>
      </Box>
    </Form>
  )
}

export default LoginForm
