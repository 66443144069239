import React from 'react'
import { Anchor, Box, Text } from 'grommet'

import * as Strings from 'common/strings'
const OutlineView = ({ children, onClose, percentageBookLoaded }) => (
  <Box
    width='medium' background='white' style={{
      borderRadius: '8px',
      padding: '16px'
    }}
  >
    <Box direction='row' margin={{ bottom: 'small' }}>
      <b style={{ flex: 1 }}>{Strings.contentsTitle}</b>
      <Anchor onClick={onClose}><b>{Strings.close}</b></Anchor>
    </Box>
    {percentageBookLoaded !== 100
      ? <Text size='xsmall'>{Strings.bookStillLoading}</Text>
      : (
          !children || children.length === 0
            ? <Text size='xsmall'>{Strings.noContentsFounds}</Text>
            : children
        )
    }
  </Box>
)

export default OutlineView
