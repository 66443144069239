import React from 'react'
import {
  Box
} from 'grommet'
import Navbar from '../common/Navbar'
import NameChangeForm from './NameChangeForm'
import EmailChangeForm from './EmailChangeForm'
import PasswordChangeForm from './PasswordChangeForm'
import PaymentSettings from './PaymentSettings'
import firebase from 'firebase'

const Settings = () => {
  firebase.analytics().logEvent('screen_view', {
    firebase_screen: 'Settings',
    firebase_screen_class: 'Settings'
  })

  return (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade' gap='medium'>
      <Navbar />
      <Box
        width='medium'
        align='center'
        justify='center'
        gap='small'
        fill
        margin={{ top: '66px' }}
      >
        <NameChangeForm />
        <EmailChangeForm />
        <PasswordChangeForm />
        <PaymentSettings />
      </Box>
    </Box>
  )
}

export default Settings
