import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Form,
  Text,
  TextInput
} from 'grommet'

import * as Strings from 'common/strings'
import { ToastsStore } from 'react-toasts'
import * as Validation from 'common/validation'
import UserContext from 'common/UserContext'

import firebase from 'firebase/app'

function handlePasswordChangeFormSubmit (currentPassword, newPassword, confirmNewPassword, user) {
  if (!Validation.validatePassword(newPassword) ||
    !Validation.validatePasswordConfirmation(newPassword, confirmNewPassword)) {
    return null
  }

  firebase.auth().currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(user.email, currentPassword))
    .then(() => {
      firebase.auth().currentUser.updatePassword(newPassword)
        .then(() => {
          ToastsStore.info(Strings.passwordUpdated, 3000, 'burntToast')
        })
        .catch(error => {
          ToastsStore.info(error.message, 3000, 'burntToast')
        })
    }).catch(error => {
      ToastsStore.info(error.message, 3000, 'burntToast')
    })
}

const PasswordChangeForm = () => {
  const { user } = useContext(UserContext)
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  return (
    <Box width='medium' background='white' round='small'>
      <Box pad='medium'>

        <Form onSubmit={
            () => {
              handlePasswordChangeFormSubmit(currentPassword, password, confirmPassword, user)
              setPassword('')
              setConfirmPassword('')
              setCurrentPassword('')
            }
          }
        >
          <Box direction='column' gap='small' fill>
            <Text size='medium'>{Strings.passwordChangeSettingHeaderText}</Text>
            <TextInput
              placeholder={Strings.currentPassword}
              className='textField'
              type='password'
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
            />
            <TextInput
              placeholder={Strings.newPassword}
              className='textField'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextInput
              placeholder={Strings.confirmNewPassword}
              className='textField'
              type='password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <Button type='submit' primary label={Strings.saveChanges} />
            <Text size='xsmall'>{Strings.passwordChangeSettingFooterText}</Text>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default PasswordChangeForm
