import React, { useEffect, useState } from 'react'
import {
  useParams
} from 'react-router-dom'

import {
  Box,
  Spinner
} from 'grommet'
import Navbar from '../common/Navbar'

import firebase from 'firebase/app'
import BookInfoCardBody from './BookInfoCardBody'

const AboutBook = () => {
  const { ISBN } = useParams()
  const [book, setBook] = useState({})
  const [isBookAvailable, setIsBookAvailable] = useState(false)

  firebase.analytics().logEvent('screen_view', {
    firebase_screen: ISBN,
    firebase_screen_class: 'AboutBook'
  })

  useEffect(() => {
    firebase.firestore()
      .collection('books')
      .doc(ISBN)
      .get()
      .then(doc => {
        setBook(doc.data())
        setIsBookAvailable(true)
      })
  }, [])

  return (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade'>
      <Navbar />
      <Box margin={{ top: '66px' }} alignSelf='center'>
        <Box
          width='large' background='white' style={{
            borderRadius: '16px',
            marginTop: '16px',
            padding: '16px'
          }}
        >
          {
            isBookAvailable
              ? <BookInfoCardBody book={book} />
              : <Box fill align='center'><Spinner /></Box>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default AboutBook
