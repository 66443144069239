import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Form,
  Text,
  TextInput
} from 'grommet'

import * as Strings from 'common/strings'
import { ToastsStore } from 'react-toasts'
import * as Validation from 'common/validation'

import UserContext from 'common/UserContext'

import firebase from 'firebase/app'

function handleNameChangeFormSubmit (name, setUser) {
  if (!Validation.validateName(name)) {
    return null
  }

  firebase.auth().currentUser.updateProfile({ displayName: name })
    .then(() => {
      ToastsStore.info(Strings.nameUpdatedMessage, 3000, 'burntToast')
      setUser(firebase.auth().currentUser)
    })
    .catch(error => {
      const errorMessage = error.message
      ToastsStore.info(errorMessage, 3000, 'burntToast')
    })
}

const NameChangeForm = () => {
  const { user, setUser } = useContext(UserContext)
  const [name, setName] = useState(user.displayName)

  return (
    <Box width='medium' background='white' round='small'>
      <Box pad='medium'>

        <Form onSubmit={
            () => handleNameChangeFormSubmit(name, setUser)
          }
        >
          <Box direction='column' gap='small' fill>
            <Text size='medium'>{Strings.nameChangeSettingHeaderText}</Text>
            <TextInput
              placeholder={Strings.name}
              className='textField'
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Button type='submit' primary label={Strings.saveChanges} />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default NameChangeForm
