import React, { useContext } from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'

import UserContext from './UserContext'

function ProtectedRoute ({ children, ...rest }) {
  const userState = useContext(UserContext)
  const publicOnly = rest.publicOnly || false
  const redirectPath = publicOnly ? '/browse' : '/'
  const condition = publicOnly ? !userState.isLoggedIn : userState.isLoggedIn

  const layoutToRender = (location) => condition
    ? children
    : (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { from: location }
        }}
      />
      )

  return (
    <Route
      {...rest}
      render={({ location }) => userState.isLoginAvailable
        ? layoutToRender(location)
        : <></>}
    />
  )
}

export default ProtectedRoute
