import React from 'react'
import { Box, Text, Spinner } from 'grommet'

import * as Strings from 'common/strings'
const NoAnnotationsCard = ({ searchMode, isContentLoaded }) => (
  <Box
    width='medium' background='white' style={{
      borderRadius: '8px',
      padding: '16px'
    }}
  >
    {
      isContentLoaded
        ? (
          <>
            <b>{Strings.noAnnotationsCardTitle}</b>
            <Text size='xsmall' margin={{ top: 'small' }}>
              {searchMode
                ? Strings.noAnnotationsCardBodySearchMode
                : Strings.noAnnotationsCardBody}
            </Text>
          </>
          )
        : (
          <Spinner size='small' alignSelf='center' />
          )
    }
  </Box>
)

export default NoAnnotationsCard
