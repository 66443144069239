import React, { useState } from 'react'
import * as Strings from 'common/strings'

import { Anchor, Box, Form, Button, TextArea } from 'grommet'

const NewNoteView = ({ onDone, pageNumber, id, savedNoteText }) => {
  const [noteText, setNoteText] = useState(savedNoteText || '')

  return (
    <Box width='medium' background='white' round='small'>
      <Box
        style={{
          borderRadius: '8px',
          padding: '16px'
        }}
      >
        <Form onSubmit={() => onDone(noteText, id)}>
          <Box direction='column' gap='small' fill>
            <TextArea
              style={{ height: '150px' }}
              resize={false}
              className='textField'
              value={noteText}
              placeholder={Strings.newNotePlaceholder(pageNumber)}
              onChange={e => setNoteText(e.target.value)}
            />
            <Button type='submit' primary label={Strings.saveNote} />
            <Anchor
              alignSelf='center'
              onClick={() => onDone(null, id)}
            >
              {Strings.cancel}
            </Anchor>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default NewNoteView
