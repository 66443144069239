import React from 'react'

import {
  Box,
  Text
} from 'grommet'

import * as Strings from 'common/strings'

const NoSearchResults = () => (
  <Box
    direction='column' className='bookCard'
    flex={false}
  >
    <b><Text size='small'>{Strings.noSearchResults}</Text></b>
    <Text size='small'>{Strings.noSearchResultsDetail}</Text>
  </Box>
)

export default NoSearchResults
