import React from 'react'
import {
  Box,
  Card,
  CardBody,
  Text
} from 'grommet'

import * as Strings from 'common/strings'
import LoginForm from './LoginForm'

const MainCard = () => (
  <Card width='medium' background='white'>
    <CardBody pad='medium'>
      <Box direction='column' gap='small' fill>
        <Box className='buckleafLogo' alignSelf='center' />
        <Text size='small' alignSelf='center' textAlign='center'>
          {Strings.tagline}
        </Text>
        <LoginForm />
      </Box>
    </CardBody>
  </Card>
)

export default MainCard
