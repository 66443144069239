import React, { useContext, useEffect, useState } from 'react'
import {
  Box
} from 'grommet'
import Navbar from '../common/Navbar'
import './Browse.css'
import Section from './Section'

import {
  useParams
} from 'react-router-dom'

import UserContext from 'common/UserContext'
import InactiveSubscriptionWarningCard from './InactiveSubscriptionWarningCard'

import firebase from 'firebase/app'
import NoSearchResults from './NoSearchResults'

const algoliasearch = require('algoliasearch')

const appId = '6DG84FEA2K'
const apiKey = 'bf17b90943980cc0212e10ddbdff3fc2'

const client = algoliasearch(appId, apiKey)
const index = client.initIndex('buckleaf')

const Search = () => {
  const { query } = useParams()

  const { userHasAccess, isPlanStatusAvailable } = useContext(UserContext)
  const showInactiveSubscriptionWarning = isPlanStatusAvailable && !userHasAccess

  const [searchResults, setSearchResults] = useState([])
  const [areSearchResultsAvailable, setAreSearchResultsAvailable] = useState(false)

  const loadBooks = async () => {
    setSearchResults([])
    setAreSearchResultsAvailable(false)

    const { hits } = await index.search(query)
    const books = []

    if (hits.length === 0) {
      setSearchResults([])
      setAreSearchResultsAvailable(true)
      return
    }

    hits.forEach(async hit => {
      const book = await firebase.firestore()
        .collection('books')
        .doc(hit.objectID)
        .get()

      books.push(book.data())
      if (books.length === hits.length) {
        setSearchResults(books)
        setAreSearchResultsAvailable(true)
      }
    })
  }

  useEffect(loadBooks, [query])

  return (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade'>
      <Navbar initialValue={query} />
      <Box margin={{ top: '66px' }}>
        {showInactiveSubscriptionWarning && <InactiveSubscriptionWarningCard />}
        <Section
          title={'Results for "' + query + '"'}
          areBooksAvailable={areSearchResultsAvailable}
          books={searchResults}
          onChange={() => {}}
          noBooksContent={<NoSearchResults />}
        />
      </Box>
    </Box>
  )
}

export default Search
