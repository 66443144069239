import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  Form,
  Text,
  TextInput
} from 'grommet'

import {
  Link,
  useHistory
} from 'react-router-dom'

import * as Strings from 'common/strings'
import { ToastsStore } from 'react-toasts'
import * as Validation from 'common/validation'

import firebase from 'firebase/app'

function handleSignupFormSubmit (name, email, password, confirmPassword, formWasSubmitted, history) {
  if (!Validation.validateName(name) ||
    !Validation.validateEmail(email) ||
    !Validation.validatePassword(password) ||
    !Validation.validatePasswordConfirmation(password, confirmPassword)) {
    return null
  }

  firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(userCredential => {
      const user = userCredential.user
      user.updateProfile({ displayName: name })
        .then(() => {
          firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .set({
              trialUsed: false,
              displayName: user.displayName
            })
            .then(() => history.push('/browse'))
        })
        .catch(error => {
          const errorMessage = error.message
          ToastsStore.info(errorMessage, 3000, 'burntToast')
          formWasSubmitted()
        })
    })
    .catch(error => {
      const errorMessage = error.message
      ToastsStore.info(errorMessage, 3000, 'burntToast')
      formWasSubmitted()
    })
}

const SignupForm = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')

  const formWasSubmitted = () => {
    setPassword('')
    setConfirmPassword('')
  }

  const history = useHistory()

  return (
    <Card width='medium' background='white'>
      <CardBody pad='medium'>
        <Form onSubmit={
            () => handleSignupFormSubmit(
              name, email, password, confirmPassword, formWasSubmitted, history)
          }
        >
          <Box direction='column' gap='small' fill>
            <Box direction='row' fill justify='between'>
              <Link to='/'><Box className='buckleafLogoNavbar' /></Link>
            </Box>
            <Text size='small'>{Strings.signupHeaderText}</Text>
            <TextInput
              placeholder={Strings.name}
              className='textField'
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <TextInput
              placeholder={Strings.email}
              className='textField'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextInput
              placeholder={Strings.password}
              className='textField'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextInput
              placeholder={Strings.confirmPassword}
              className='textField'
              type='password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <Button type='submit' primary label={Strings.signUp} />
            <Text size='xsmall'>{Strings.signupHeaderText2}</Text>
          </Box>
        </Form>
      </CardBody>
    </Card>
  )
}

export default SignupForm
