import React, { useState, useContext } from 'react'
import UserContext from 'common/UserContext'
import { showStripePortal, invokeStripe } from 'common/stripeUtils'

import {
  Box,
  Button,
  Text,
  Spinner
} from 'grommet'

import * as Strings from 'common/strings'

const PaymentSettings = () => {
  const [waitingForStripe, setWaitingForStripe] = useState(false)
  const {
    planStatus,
    isPlanStatusAvailable,
    userHasAccess,
    user
  } = useContext(UserContext)

  const inactiveSubscription = isPlanStatusAvailable && !userHasAccess

  return (
    <Box width='medium' background='white' round='small'>
      <Box pad='medium'>
        <Box direction='column' gap='small' fill>
          <Box direction='row' fill justify='between'>
            <Text size='medium'>{Strings.billingSettingHeaderText}</Text>
            {(waitingForStripe || !isPlanStatusAvailable) && <Spinner size='xsmall' />}
          </Box>
          <Text size='small'>
            <b>
              {
                (
                  planStatus === 'trialing'
                    ? Strings.billingSettingsFreeTrialText
                    : (
                        planStatus === 'active'
                          ? Strings.billingSettingsActiveText
                          : Strings.billingSettingsInactiveText
                      )
                )
              }
            </b>
          </Text>
          <Text size='small'>{Strings.aboutCustomerPortal}</Text>
          <Button
            disabled={!isPlanStatusAvailable}
            type='submit' primary label={Strings.customerPortalButtonText} onClick={() => {
              setWaitingForStripe(true)
              if (inactiveSubscription) {
                invokeStripe(user.uid)
              } else {
                showStripePortal()
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentSettings
