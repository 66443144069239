import React, { useContext, useState } from 'react'
import { Box } from 'grommet'
import SignupForm from './SignupForm'

import UserContext from 'common/UserContext'
import firebase from 'firebase'

import {
  Redirect
} from 'react-router-dom'

const Signup = () => {
  const [waitingForStripe, setWaitingForStripe] = useState(false)
  const { isLoggedIn, isLoginAvailable } = useContext(UserContext)
  const alreadyLoggedIn = isLoggedIn && isLoginAvailable

  if (isLoginAvailable && !isLoggedIn) {
    firebase.analytics().logEvent('screen_view', {
      firebase_screen: 'Signup',
      firebase_screen_class: 'Signup'
    })
  }

  if (!isLoginAvailable) {
    return <></>
  }

  return (alreadyLoggedIn && !waitingForStripe)
    ? (
      <Redirect
        to={{
          pathname: '/browse'
        }}
      />
      )
    : (
      <Box fill className='mainLandingContainer fade'>
        <Box
          width='medium'
          align='center'
          justify='center'
          gap='small'
          fill
        >
          <SignupForm setWaitingForStripe={setWaitingForStripe} waitingForStripe={waitingForStripe} />
        </Box>
      </Box>
      )
}

export default Signup
