import React from 'react'

import * as Strings from 'common/strings'

import {
  Anchor,
  Card,
  CardBody,
  Text
} from 'grommet'

import {
  Link
} from 'react-router-dom'

const BottomCard = () =>
  (
    <Card width='medium' background='white'>
      <CardBody pad='medium'>
        <Text size='small'>
          {Strings.about}<b>{Strings.freeTrial}</b>{Strings.aboutContinued}
        </Text>
        <br />
        <Text size='small'>
          {Strings.dontHaveAnAccount}
          &nbsp;
          <Link to='/signup' className='routerLink'>
            <Anchor>{Strings.signUpHere}</Anchor>
          </Link>
        </Text>
        <Text size='xsmall' className='copyrightText'>
          {Strings.copyright}
        </Text>
      </CardBody>
    </Card>
  )

export default BottomCard
