import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Form,
  Text,
  TextInput
} from 'grommet'

import * as Strings from 'common/strings'
import { ToastsStore } from 'react-toasts'
import * as Validation from 'common/validation'
import UserContext from 'common/UserContext'

import firebase from 'firebase/app'

function handleEmailChangeFormSubmit (email, password, user, setUser) {
  if (!Validation.validateEmail(email)) {
    return null
  }

  firebase.auth().currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(user.email, password))
    .then(() => {
      firebase.auth().currentUser.updateEmail(email)
        .then(() => {
          ToastsStore.info(Strings.emailUpdatedMessage, 3000, 'burntToast')
          setUser(firebase.auth().currentUser)
        })
        .catch(error => {
          ToastsStore.info(error.message, 3000, 'burntToast')
        })
    }).catch(error => {
      ToastsStore.info(error.message, 3000, 'burntToast')
    })
}

const EmailChangeForm = () => {
  const { user, setUser } = useContext(UserContext)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState('')

  return (
    <Box width='medium' background='white' round='small'>
      <Box pad='medium'>

        <Form onSubmit={
            () => {
              handleEmailChangeFormSubmit(email, password, user, setUser)
              setPassword('')
            }
          }
        >
          <Box direction='column' gap='small' fill>
            <Text size='medium'>{Strings.emailChangeSettingHeaderText}</Text>
            <TextInput
              placeholder={Strings.currentPassword}
              className='textField'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextInput
              placeholder={Strings.name}
              className='textField'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Button type='submit' primary label={Strings.saveChanges} />
            <Text size='xsmall'>{Strings.emailChangeSettingFooterText}</Text>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default EmailChangeForm
