import React, { useState, useEffect } from 'react'
import HighlightCard from './HighlightCard'
import NoteCard from './NoteCard'
import NoAnnotationsCard from './NoAnnotationsCard'
import { Box, TextInput } from 'grommet'
import { Search } from 'grommet-icons'

import * as Strings from 'common/strings'

const AnnotationView = ({ annotations, onEdit, onDelete, isContentLoaded }) => {
  const [query, setQuery] = useState('')
  const [shownAnnotations, setShownAnnotations] = useState([...annotations])

  useEffect(() => setShownAnnotations([...annotations]), [annotations])

  const annotationView = shownAnnotations.map(({ id, highlightedText, noteText, pageNumber, highlight }) => {
    return highlightedText
      ? (
        <HighlightCard
          highlightedText={highlightedText}
          noteText={noteText}
          pageNumber={pageNumber}
          color={highlight.color}
          id={id}
          onDelete={onDelete}
          onEdit={onEdit}
        />
        )
      : (
        <NoteCard
          noteText={noteText}
          pageNumber={pageNumber}
          id={id}
          onDelete={onDelete}
          onEdit={onEdit}
        />
        )
  })

  useEffect(() => {
    setShownAnnotations(annotations.filter(annotation => (
      annotation.noteText.toLowerCase().includes(query.toLowerCase()) ||
      ('highlightedText' in annotation && annotation.highlightedText.toLowerCase().includes(query.toLowerCase()))
    )))
  }, [query])

  return (
    <Box width='medium' pad='none' direction='column' justify='start' gap='small'>
      <TextInput
        className='textFieldFloating'
        placeholder={Strings.findNotesAndHighlightsPlaceholder}
        icon={<Search size='small' />}
        reverse
        value={query}
        onChange={event => setQuery(event.target.value.trim())}
      />

      {annotationView.length > 0 ? annotationView : <NoAnnotationsCard isContentLoaded={isContentLoaded} searchMode={query.trim() !== ''} />}
    </Box>
  )
}

export default AnnotationView
