import React from 'react'

import BottomCard from './BottomCard'
import MainCard from './MainCard/'

import './Landing.css'
import { Box } from 'grommet'
import firebase from 'firebase'

const Landing = () => {
  firebase.analytics().logEvent('screen_view', {
    firebase_screen: 'Landing',
    firebase_screen_class: 'Landing'
  })

  return (
    <Box fill className='mainLandingContainer fade'>
      <Box
        width='medium'
        align='center'
        justify='center'
        gap='small'
        fill
      >
        <MainCard />
        <BottomCard />
      </Box>
    </Box>
  )
}

export default Landing
