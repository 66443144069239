import React, { useContext, useState } from 'react'
import {
  Anchor,
  Box,
  Text,
  Spinner
} from 'grommet'
import UserContext from 'common/UserContext'
import * as Strings from 'common/strings'
import { invokeStripe } from 'common/stripeUtils'
import {
  Link
} from 'react-router-dom'

const InactiveSubscriptionWarningCard = () => {
  const { user } = useContext(UserContext)
  const [waitingForStripe, setWaitingForStripe] = useState(false)

  return (
    <Box
      flex={false}
      alignSelf='center'
      width='medium'
      justify='center'
      style={{
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '16px',
        marginTop: '16px'
      }}
    >
      <Box direction='row' justify='between'>
        <Text size='small'><b>{Strings.inactiveSubscriptionWarningCardHeader}</b></Text>
        {waitingForStripe && <Spinner size='xsmall' />}
      </Box>
      <Text size='small' margin={{ top: 'medium', bottom: 'small' }}>
        {Strings.inactiveSubscriptionWarningCardBody1} <b>{Strings.guarantee}</b> {Strings.inactiveSubscriptionWarningCardBody2}
      </Text>

      <Text size='small'>
        <Link to='/premium' className='routerLink'>
          <Anchor>Learn More</Anchor>
        </Link>
        <Text> &middot; </Text>
        <Anchor onClick={() => {
          setWaitingForStripe(true)
          invokeStripe(user.uid)
        }}
        >
          {Strings.getPremium}
        </Anchor>
      </Text>
    </Box>
  )
}

export default InactiveSubscriptionWarningCard
