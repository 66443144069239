import React, { useContext } from 'react'

import {
  Box,
  Text
} from 'grommet'
import BookCard from './BookCard'
import LoadingCard from './LoadingCard'

import UserContext from 'common/UserContext'

const Section = props => {
  const {
    areBooksAvailable,
    books,
    onChange,
    noBooksContent
  } = props
  const { user } = useContext(UserContext)
  let contents = areBooksAvailable
    ? books.map(book => <BookCard key={book.id} book={book} isBookInUsersLibrary={book.users.includes(user.uid)} onAddOrRemove={onChange} />)
    : (
      <LoadingCard />
      )

  if (contents.length === 0) {
    contents = noBooksContent
  }

  return (
    <Box>
      <Text
        className='browseSectionHeader'
      >
        {props.title}
      </Text>
      <Box direction='row' wrap>
        {contents}
      </Box>
    </Box>
  )
}

export default Section
