import React, { useState } from 'react'
import {
  Anchor,
  Box,
  TextInput,
  Form
} from 'grommet'
import './Navbar.css'
import {
  Link,
  useHistory
} from 'react-router-dom'
import firebase from 'firebase/app'

import { FormSearch } from 'grommet-icons'
import * as Strings from 'common/strings'

const Navbar = ({ placeholder, onReturn, initialValue }) => {
  const [query, setQuery] = useState(initialValue || '')
  const history = useHistory()

  return (
    <Box
      as='nav'
      alignSelf='stretch'
      className='navbar'
      direction='row'
      gap='medium'
      pad={{
        left: '16px',
        right: '16px'
      }}
      align='center'
      elevation='xsmall'
    >
      <Link to='/browse'>
        <Box className='buckleafLogoNavbar' />
      </Link>

      <Box flex direction='row' justify='center'>
        <Form
          className='searchForm' onSubmit={() => {
            if (onReturn) {
              onReturn(query)
            } else {
              history.push('/search/' + encodeURIComponent(query))
            }
          }}
        >
          <TextInput
            className='textField'
            placeholder={placeholder || Strings.searchPlaceholder}
            icon={<FormSearch />}
            value={query}
            onChange={e => setQuery(e.target.value)}
            reverse
            id='buckleaf-search-bar'
          />
        </Form>
      </Box>
      <Link to='/premium' className='routerLink'>
        <Anchor>Buckleaf Premium</Anchor>
      </Link>
      <Link to='/settings' className='routerLink'>
        <Anchor>Settings</Anchor>
      </Link>
      <Anchor
        onClick={() => {
          firebase.auth().signOut().then(() => {
            history.push('/')
          })
        }}
      >Sign out
      </Anchor>
    </Box>
  )
}

export default Navbar
