import React from 'react'
import {
  Box,
  Button,
  Image,
  Text
} from 'grommet'

import {
  Link
} from 'react-router-dom'

const BookInfoCardBody = ({ book }) => (
  <Box direction='row' gap='medium'>
    <Box gap='small'>
      <Image
        src={book.thumbnail}
        width='225px'
        height='348px'
        alignSelf='start'
        margin={{ bottom: 'medium' }}
      />
      <Link
        to={'/read/' + book.ISBN} align='center' style={{
          textDecoration: 'none'
        }}
      >
        <Box fill>
          <Button primary label='Read' />
        </Box>
      </Link>
      <Button label='Add to my books' />
    </Box>
    <Box flex>
      <Text>
        <b>{book.title}</b>
      </Text>

      <Text>
        {book.subtitle}
      </Text>

      <Text size='small'>
        {book.authors} {book.year && <span>&middot;</span>} {book.year || ''}
      </Text>

      <Text size='xsmall' margin={{ top: 'medium' }}>
        <b>ISBN:</b> {book.ISBN}
      </Text>

      <Text size='small' margin={{ top: 'medium' }}>
        {book.description || ''}
      </Text>

      <Text size='small' margin={{ top: 'medium' }}>
        <b>Categories:</b> {book.categories.join(', ')}
      </Text>
    </Box>
  </Box>
)

export default BookInfoCardBody
