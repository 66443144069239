import React from 'react'

import {
  Box,
  Spinner
} from 'grommet'

const LoadingCard = () => (
  <Box
    direction='column' className='bookCard'
    flex={false}
  >
    <Spinner />
  </Box>
)

export default LoadingCard
