import React, { useContext, useEffect, useState } from 'react'
import {
  Box
} from 'grommet'
import Navbar from '../common/Navbar'
import './Browse.css'
import Section from './Section'
import NoBooksCard from './NoBooksCard'

import UserContext from 'common/UserContext'
import InactiveSubscriptionWarningCard from './InactiveSubscriptionWarningCard'

import firebase from 'firebase/app'

const Browse = () => {
  const { userHasAccess, isPlanStatusAvailable, user } = useContext(UserContext)
  const showInactiveSubscriptionWarning = isPlanStatusAvailable && !userHasAccess

  const [yourBooks, setYourBooks] = useState([])
  const [allBooks, setAllBooks] = useState([])
  const [areYourBooksAvailable, setAreYourBooksAvailble] = useState(false)
  const [areAllBooksAvailable, setAreAllBooksAvailable] = useState(false)

  firebase.analytics().logEvent('screen_view', {
    firebase_screen: 'Browse',
    firebase_screen_class: 'Browse'
  })

  const loadBooks = () => {
    if (user == null) {
      return
    }

    firebase.firestore()
      .collection('books')
      .where('users', 'array-contains', user.uid)
      .get()
      .then(querySnapshot => {
        const books = []

        querySnapshot.forEach(async doc => {
          books.push(doc.data())
        })

        setYourBooks(books)
        setAreYourBooksAvailble(true)
      })

    firebase.firestore()
      .collection('users')
      .doc(user.uid)
      .set({
        displayName: user.displayName
      }, { merge: true })

    firebase.firestore()
      .collection('books')
      .get()
      .then(querySnapshot => {
        const books = { }

        querySnapshot.forEach(async doc => {
          const book = doc.data()

          for (let i = 0; i < book.categories.length; i++) {
            if (book.categories[i] in books) {
              books[book.categories[i]].push(book)
            } else {
              books[book.categories[i]] = [book]
            }
          }
        })

        setAllBooks(books)
        setAreAllBooksAvailable(true)
      })
  }

  useEffect(loadBooks, [user])

  const allBooksSections = Object.keys(allBooks).sort((a, b) => allBooks[b].length - allBooks[a].length).map(key => (
    <Section
      key={key}
      title={key}
      areBooksAvailable={areAllBooksAvailable}
      books={allBooks[key]}
      onChange={loadBooks}
    />
  ))

  return (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade'>
      <Navbar />
      <Box margin={{ top: '66px' }}>
        {showInactiveSubscriptionWarning && <InactiveSubscriptionWarningCard />}
        <Section
          title='Your Books'
          areBooksAvailable={areYourBooksAvailable}
          books={yourBooks}
          onChange={loadBooks}
          noBooksContent={<NoBooksCard />}
        />
        {allBooksSections}
      </Box>
    </Box>
  )
}

export default Browse
