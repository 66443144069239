import React, { useEffect, useState, useContext } from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import { useParams, useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import Document from './Document'
import { Anchor, Box, Tip, Button, Text, TextInput, Meter } from 'grommet'
import { Amazon, ChapterAdd, Close, Erase, Menu, Search } from 'grommet-icons'
import AnnotationView from './components/AnnotationView'
import NewNoteView from './components/NewNoteView'
import NewHighlightView from './components/NewHighlightView'
import SearchView from './components/SearchView'

import * as Strings from 'common/strings'

import { ToastsStore } from 'react-toasts'
import OutlineView from './components/OutlineView'

import UserContext from 'common/UserContext'

const Mode = Object.freeze({
  ANNOTATION: 1,
  NEW_NOTE: 2,
  NEW_HIGHLIGHT: 3,
  SEARCH: 4,
  EDIT_NOTE: 5,
  EDIT_HIGHLIGHT: 6,
  OUTLINE: 7
})

function getSelectedText () {
  var text = ''
  if (window.getSelection) {
    text = window.getSelection().toString()
  } else if (document.selection && document.selection.type !== 'Control') {
    text = document.selection.createRange().text
  }
  return text
}

function clearTextSelection () {
  if (window.getSelection) {
    if (window.getSelection().empty) { // Chrome
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) { // Firefox
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) { // IE?
    document.selection.empty()
  }
}

const ReadBook = () => {
  const storage = firebase.storage()
  const storageRef = storage.ref()

  const { ISBN } = useParams()

  firebase.analytics().logEvent('screen_view', {
    firebase_screen: ISBN,
    firebase_screen_class: 'Reader'
  })

  const [pdfViewerLoaded, setPdfViewerLoaded] = useState(false)
  const [isContentLoaded, setContentLoaded] = useState(false)
  const [percentageBookLoaded, setPercentageBookLoaded] = useState(0)

  const [pdfSource, setPdfSource] = useState(null)

  const [annotations, setAnnotations] = useState([])
  const [areAnnotationsLoaded, setAreAnnotationsLoaded] = useState(false)
  const { user } = useContext(UserContext)

  const [highlights, setHighlights] = useState(
    annotations
      .filter(annotation => 'highlight' in annotation)
      .map(annotation => annotation.highlight)
  )
  const [currentPageNumber, setPageNumber] = useState(1)
  const [currentMatches, setCurrentMatches] = useState(0)
  const [totalMatches, setTotalMatches] = useState(0)
  const [searchState, setSearchState] = useState(0)
  const [bookSearchQuery, setBookSearchQuery] = useState('')
  const [pdfFindController, setPdfFindController] = useState(null)
  const history = useHistory()
  const [currentSelection, setCurrentSelection] = useState(null)
  const [currentlySelectedText, setCurrentlySelectedText] = useState('')

  const [currentMode, setCurrentMode] = useState(Mode.ANNOTATION)
  const [currentlyActiveAnnotation, setCurrentlyActiveAnnotation] = useState(null)

  const [currentView, setCurrentView] = useState((
    <AnnotationView
      isContentLoaded={areAnnotationsLoaded}
      annotations={annotations}
      key={`${currentMode}-${annotations.toString()}`}
      onDelete={onAnnotationDelete}
      onEdit={onAnnotationEdit}
    />
  ))

  function saveBookUsage () {
    firebase.firestore()
      .collection('users')
      .doc(user.uid)
      .collection('book-usage')
      .doc(ISBN)
      .set({
        annotations: annotations,
        lastReadPageNumber: currentPageNumber
      }, { merge: true })
  }

  function handleSaveNote (noteText, id) {
    setCurrentlyActiveAnnotation(null)
    if (noteText) {
      setAnnotations([...annotations.filter(annotation => annotation.id !== id), {
        pageNumber: currentPageNumber,
        noteText: noteText,
        id: id || Date.now()
      }].sort((a, b) => (a.pageNumber - b.pageNumber !== 0
        ? a.pageNumber - b.pageNumber
        : a.id - b.id)))
    }

    saveBookUsage()
    setCurrentMode(Mode.ANNOTATION)
  }

  function handleSaveHighlight (noteText, color, id) {
    setCurrentlyActiveAnnotation(null)
    if (noteText == null) {
      setCurrentMode(Mode.ANNOTATION)
      return
    }

    const selection = { ...currentSelection, color: color }

    setAnnotations([...annotations.filter(annotation => annotation.id !== id), {
      pageNumber: currentPageNumber,
      noteText: noteText,
      highlightedText: currentlySelectedText,
      highlight: selection,
      id: id || Date.now()
    }].sort((a, b) => (a.pageNumber - b.pageNumber !== 0
      ? a.pageNumber - b.pageNumber
      : a.id - b.id)))

    saveBookUsage()
    setHighlights([...highlights, selection])
    setCurrentMode(Mode.ANNOTATION)
  }

  function onAnnotationEdit (id) {
    const annotation = annotations.find(annotation => annotation.id === id)
    setCurrentlyActiveAnnotation(annotation)
    setCurrentMode('highlight' in annotation ? Mode.EDIT_HIGHLIGHT : Mode.EDIT_NOTE)

    if ('highlight' in annotation) {
      setCurrentlySelectedText(annotation.highlightedText)
      setCurrentSelection(annotation.highlight)
    }
  }

  function onAnnotationDelete (id) {
    const newAnnotations = [...annotations.filter(annotation => annotation.id !== id)]
    setAnnotations(newAnnotations)
    setHighlights(
      newAnnotations
        .filter(annotation => 'highlight' in annotation)
        .map(annotation => annotation.highlight)
    )
  }

  const [outlineView, setOutlineView] = useState((
    <OutlineView
      onClose={() => setCurrentMode(Mode.ANNOTATION)}
      percentageBookLoaded={percentageBookLoaded}
    />
  ))

  useEffect(() => {
    if (currentMode === Mode.ANNOTATION) {
      setCurrentView((
        <AnnotationView
          isContentLoaded={areAnnotationsLoaded}
          annotations={annotations}
          key={currentMode}
          onDelete={onAnnotationDelete}
          onEdit={onAnnotationEdit}
        />
      ))
    } else if (currentMode === Mode.NEW_NOTE) {
      setCurrentView(<NewNoteView onDone={handleSaveNote} pageNumber={currentPageNumber} key={currentMode} />)
    } else if (currentMode === Mode.EDIT_NOTE) {
      setCurrentView(<NewNoteView id={currentlyActiveAnnotation.id} savedNoteText={currentlyActiveAnnotation.noteText} onDone={handleSaveNote} pageNumber={currentPageNumber} key={currentMode} />)
    } else if (currentMode === Mode.EDIT_HIGHLIGHT) {
      setCurrentView(<NewHighlightView id={currentlyActiveAnnotation.id} savedNoteText={currentlyActiveAnnotation.noteText} savedColor={currentlyActiveAnnotation.highlight.color} onDone={handleSaveHighlight} text={currentlySelectedText} key={currentMode} />)
    } else if (currentMode === Mode.NEW_HIGHLIGHT) {
      setCurrentView(<NewHighlightView onDone={handleSaveHighlight} text={currentlySelectedText} key={currentMode} />)
    } else if (currentMode === Mode.SEARCH) {
      setCurrentView((
      <SearchView
        currentMatches={currentMatches}
        key={currentMode}
        totalMatches={totalMatches}
        setBookSearchQuery={searchText => {
          if (searchText === undefined || searchText === null) {
            return
          }
          pdfFindController.executeCommand('find', {
            caseSensitive: false,
            highlightMatches: true,
            phraseSearch: true,
            highlightAll: true,
            query: searchText || ''
          })
          setBookSearchQuery(searchText)
        }}
        searchState={searchState}
        next={() => {
          if (!pdfViewerLoaded || bookSearchQuery === undefined || bookSearchQuery === null) {
            return
          }

          pdfFindController.executeCommand('findagain', {
            caseSensitive: false,
            findPrevious: false, // next
            phraseSearch: true,
            highlightAll: true,
            query: bookSearchQuery || ''
          })
        }}
        previous={() => {
          if (!pdfViewerLoaded || bookSearchQuery === undefined || bookSearchQuery === null) {
            return
          }

          pdfFindController.executeCommand('findagain', {
            caseSensitive: false,
            findPrevious: true,
            phraseSearch: true,
            highlightAll: true,
            query: bookSearchQuery || ''
          })
        }}
        cancel={() => {
          setBookSearchQuery('')
          setCurrentMode(Mode.ANNOTATION)

          pdfFindController.executeCommand('findagain', {
            caseSensitive: false,
            findPrevious: false, // next
            phraseSearch: true,
            highlightAll: true,
            query: ''
          })
        }}
      />
      ))
    } else if (currentMode === Mode.OUTLINE) {
      setCurrentView(outlineView)
    }
  }, [areAnnotationsLoaded, annotations, outlineView, currentMode, currentPageNumber, currentMatches, totalMatches, searchState])

  useEffect(() => {
    firebase.firestore()
      .collection('users')
      .doc(user.uid)
      .collection('book-usage')
      .doc(ISBN)
      .get()
      .then(doc => {
        setAreAnnotationsLoaded(true)
        if (!doc.exists) return

        setAnnotations(doc.data().annotations)
      })

    storageRef.child(`raw-books/${ISBN}.pdf`)
      .getDownloadURL()
      .then(url => {
        setPdfSource(url)
      })
  }, [])

  function handleOnTextSelected (selection) {
    setCurrentSelection(selection)
    setCurrentlySelectedText(getSelectedText())
  }

  const [pageNumberTextFieldValue, setPageNumberTextFieldValue] = useState(currentPageNumber)

  function handleOnPageNumberChange (newPageNumber) {
    setPageNumber(newPageNumber)
    setPageNumberTextFieldValue(newPageNumber)
  }

  function onSearch (currentMatches, totalMatches) {
    setCurrentMatches(currentMatches)
    setTotalMatches(totalMatches)
  }

  function onSearchStateUpdate (ss) {
    setSearchState(ss)
  }

  function onLoad (p) {
    setPdfFindController(p)
    setPdfViewerLoaded(true)
  }

  const [pdfViewer, setPdfViewer] = useState(null)
  const [totalPages, setTotalPages] = useState(0)

  async function onOutlineLoad (outline, loadedPdf, pdfViewer) {
    const outlineComponents = []
    setPdfViewer(pdfViewer)

    firebase.firestore()
      .collection('users')
      .doc(user.uid)
      .collection('book-usage')
      .doc(ISBN)
      .get()
      .then(doc => {
        setAreAnnotationsLoaded(true)
        if (!doc.exists) return
        setAnnotations(doc.data().annotations)
        setPageNumber(doc.data().lastReadPageNumber)
        setHighlights(
          doc.data().annotations
            .filter(annotation => 'highlight' in annotation)
            .map(annotation => annotation.highlight)
        )
        pdfViewer.current.scrollPageIntoView({ pageNumber: doc.data().lastReadPageNumber })
      })

    setTotalPages(loadedPdf.numPages)
    const makeOutline = async (items, level) => {
      if (level === 3 || items === null) {
        return
      }

      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.dest === null) {
          continue
        }

        try {
          const pageNumber = await loadedPdf.getPageIndex(item.dest[0]) + 1

          outlineComponents.push(<Anchor style={{ marginLeft: `${level * 15}px` }} onClick={() => pdfViewer.current.scrollPageIntoView({ pageNumber: pageNumber })}><Text size='xsmall'><b>{item.title}</b></Text></Anchor>)
          await makeOutline(item.items, level + 1)
        } catch (e) {
          console.log(e)
        }
      }
    }

    await makeOutline(outline, 0)
    if (outline !== null) {
      setOutlineView((
        <OutlineView
          onClose={() => setCurrentMode(Mode.ANNOTATION)}
          percentageBookLoaded={100}
        >
            {outlineComponents}
        </OutlineView>
      ))
    } else {
      setOutlineView((
        <OutlineView
          onClose={() => setCurrentMode(Mode.ANNOTATION)}
          percentageBookLoaded={100}
        />
      ))
    }
    setContentLoaded(true)
  }

  return (
    <Box full direction='row'>
      {percentageBookLoaded !== 100 &&
      (
        <Meter
          type='circle'
          value={percentageBookLoaded}
          color='#FD930D'
          background='light-5'
          width='50px'
          size='small'
          alignSelf='center'
          round
          style={{
            position: 'fixed',
            top: '-50px',
            right: '15px'
          }}
        />
      )
      }
      <Box
        direction='column' justify='center' gap='small' pad='small'
        margin='small'
        style={{ maxHeight: '100%', position: 'fixed' }}
      >
        <Tip content='Close'>
          <Button
            icon={<Close color='#FD930D' />}
            hoverIndicator
            alignSelf='center'
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 1px 2px 2px #11111105'
            }}
            onClick={() => {
              history.push('/browse')
              saveBookUsage()
            }}
          />
        </Tip>

        <Tip content='Contents'>
          <Button
            icon={<Menu color='#FD930D' />}
            hoverIndicator
            alignSelf='center'
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 1px 2px 2px #11111105'
            }}
            onClick={() => setCurrentMode(Mode.OUTLINE)}
          />
        </Tip>

        <Tip content='Note'>
          <Button
            icon={<ChapterAdd color='#FD930D' />}
            hoverIndicator
            alignSelf='center'
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 1px 2px 2px #11111105'
            }}
            onClick={() => setCurrentMode(Mode.NEW_NOTE)}
          />
        </Tip>

        <Tip content='Highlight'>
          <Button
            icon={<Erase color='#FD930D' />}
            hoverIndicator
            alignSelf='center'
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 1px 2px 2px #11111105'
            }}
            onClick={() => {
              if (currentSelection === null) {
                ToastsStore.info(Strings.selectTextToHighlightError, 3000, 'burntToast')
              } else {
                clearTextSelection()
                setCurrentMode(Mode.NEW_HIGHLIGHT)
              }
            }}
          />
        </Tip>

        <Tip content='Search'>
          <Button
            icon={<Search color='#FD930D' />}
            hoverIndicator
            alignSelf='center'
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 1px 2px 2px #11111105'
            }}
            onClick={() => setCurrentMode(Mode.SEARCH)}
          />
        </Tip>

        <Tip content='Buy'>
          <Button
            icon={<Amazon color='#FD930D' />}
            hoverIndicator
            alignSelf='center'
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 1px 2px 2px #11111105'
            }}
            onClick={() => window.open(`https://www.amazon.com/s?k=${ISBN}`, '_blank').focus()}
          />
        </Tip>

        {isContentLoaded &&
        (
          <>
            <Tip content='This page'>
              <TextInput
                className='textFieldFloating'
                style={{ width: 50, alignSelf: 'center', marginTop: 50 }} textAlign='center'
                value={pageNumberTextFieldValue}
                onChange={e => {
                  let value = parseInt(e.target.value)
                  if (value) {
                    if (value < 1) {
                      value = 1
                    } else if (value > totalPages) {
                      value = totalPages
                    }
                    setPageNumberTextFieldValue(value)
                    pdfViewer.current.scrollPageIntoView({ pageNumber: value })
                  } else if (e.target.value.trim() === '') {
                    setPageNumberTextFieldValue('')
                  }
                }}
              />
            </Tip>
            <Tip content='Total pages'><Text size='small' alignSelf='center'>{totalPages}</Text></Tip>
          </>
        )
        }
      </Box>
      <Box width='medium' pad='medium' direction='column' justify='start' gap='small' margin={{ left: '75px' }}>
        <CSSTransitionGroup
          transitionName='fade'
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {currentView}
        </CSSTransitionGroup>
      </Box>
      {pdfSource &&
        (
            <Document
              highlights={highlights}
              onPageNumberChange={handleOnPageNumberChange}
              onTextSelected={handleOnTextSelected}
              pageNumber={currentPageNumber}
              scale={1}
              source={pdfSource}
              onSearch={onSearch}
              onSearchStateUpdate={onSearchStateUpdate}
              onLoad={onLoad}
              onOutlineLoad={onOutlineLoad}
              setPercentageBookLoaded={setPercentageBookLoaded}
            />
        )
      }
    </Box>
  )
}

export default ReadBook
