import React from 'react'

export default function HighlightBox ({ items, scale = 1 }) {
  return (
    <>
      {items.map((item, key) => (
        <div key={key}>
          {item.boundingBoxes.map((box, i) => (
            <div
              style={{
                position: 'absolute',
                left: box.left * scale,
                top: box.top * scale,
                height: box.height * scale,
                width: box.width * scale,
                backgroundColor: item.color || '#FF6900',
                opacity: 0.3,
                cursor: 'pointer',
                zIndex: 1000
              }}
              key={i}
            />
          ))}
        </div>
      ))}
    </>
  )
}
