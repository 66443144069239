import React, { useEffect, useState, useRef } from 'react'
import is from 'is_js'
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf'
// import pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry'
import * as pdfjsViewer from 'pdfjs-dist/es5/web/pdf_viewer'
import 'pdfjs-dist/es5/web/pdf_viewer.css'
import './Document.css'
import { addHighlight } from './addHighlight'

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.worker.js'
const eventBus = new pdfjsViewer.EventBus()
const pdfLinkService = new pdfjsViewer.PDFLinkService({ eventBus })
const pdfFindController = new pdfjsViewer.PDFFindController({ eventBus, linkService: pdfLinkService })

export default function Document ({
  scale = 1.0,
  pageNumber = 1,
  source,
  onError,
  onTextSelected,
  highlights = [],
  onPageNumberChange,
  onSearch,
  onSearchStateUpdate,
  onLoad,
  onOutlineLoad,
  setPercentageBookLoaded
}) {
  const [currentPage, setCurrentPage] = useState(pageNumber)
  const [renderedPage, setRenderedPage] = useState(null)
  const [numPages, setNumPages] = useState(0)

  const pdfViewer = useRef(null)

  // ================== select ==================

  const handleOnSelect = (e) => {
    const boundingBoxes = []
    const s = document.getSelection()
    const boxes = s.getRangeAt(0).getClientRects()

    const startPage = s.anchorNode?.parentNode?.parentNode?.parentNode?.getAttribute('data-page-number')
    const endPage = s.focusNode?.parentNode?.parentNode?.parentNode?.getAttribute('data-page-number')
    const isCollapsed = s.isCollapsed

    if (!startPage || !endPage || isCollapsed || startPage !== endPage) {
      onTextSelected(null)
      return
    }

    for (let i = 0; i < boxes.length; i++) {
      if (i % 2 === 0 && i !== 0 && is.not.firefox()) continue // fix duplicate rects problem in chrome
      const box = getBoundingBox(boxes.item(i), startPage)
      boundingBoxes.push(box)
    }
    onTextSelected({ boundingBoxes, color: '#FF6900', page: startPage })
  }

  const getBoundingBox = (box, page) => {
    const _textLayer = document.getElementById(`highlight-page-${page}`)
    const _box = {
      left: (box.left - _textLayer.getBoundingClientRect().left + _textLayer.scrollLeft) / scale,
      top: (box.top - _textLayer.getBoundingClientRect().top + _textLayer.scrollTop) / scale,
      height: box.height / scale,
      width: box.width / scale
    }

    return _box
  }

  useEffect(() => {
    onLoad(pdfFindController)
  }, [pdfFindController])

  // ================== events ==================

  useEffect(() => {
    eventBus.on('pagechanging', function (e) {
      setCurrentPage(e.pageNumber)
      if (onPageNumberChange) onPageNumberChange(e.pageNumber)
    })

    eventBus.on('updatefindmatchescount', e => {
      onSearch(e.matchesCount.current, e.matchesCount.total)
    })

    // https://stackoverflow.com/a/68855341/2672265
    eventBus.on('updatefindcontrolstate', e => {
      onSearchStateUpdate(e.state) // FOUND: 0, NOT_FOUND: 1, WRAPPED: 2, PENDING: 3
    })

    eventBus.on('pagerendered', (e) => {
      const page = document.querySelector(`[data-page-number="${e.pageNumber}"]`)
      const highlightLayer = document.createElement('div')
      highlightLayer.setAttribute('id', `highlight-page-${e.pageNumber}`)
      highlightLayer.setAttribute('class', 'textLayer snippet-highlight')
      page.appendChild(highlightLayer)
      setRenderedPage(e.pageNumber)
    })

    eventBus.on('pagesinit', function () {
      pdfViewer.current.currentScaleValue = scale
      pdfViewer.current.scrollPageIntoView({ pageNumber: currentPage })
    })

    const container = document.getElementById('viewerContainer')
    const viewer = document.getElementById('viewer')

    pdfViewer.current = new pdfjsViewer.PDFViewer({
      container,
      viewer,
      eventBus,
      findController: pdfFindController,
      linkService: pdfLinkService
    })

    pdfLinkService.setViewer(pdfViewer.current)

    const loadingTask = pdfjsLib.getDocument({ url: source })
    loadingTask.onProgress = function (data) {
      setPercentageBookLoaded(parseInt(data.loaded / data.total * 100.0))
    }

    loadingTask.promise.then(
      async (loadedPdf) => {
        pdfViewer.current.setDocument(loadedPdf)
        pdfLinkService.setDocument(loadedPdf, null)
        setNumPages(loadedPdf.numPages)

        // Get the tree outline
        const outline = await loadedPdf.getOutline()
        onOutlineLoad(outline, loadedPdf, pdfViewer)
      },
      function (error) {
        console.error(error)
      }
    )
  }, [source])

  // ================== updating ==================

  useEffect(() => {
    addHighlight(highlights, scale, numPages)
  }, [highlights, renderedPage, scale])

  useEffect(() => {
    if (pdfViewer.current) {
      pdfViewer.current.currentScaleValue = scale
    }
  }, [scale])

  return (
    <div className='document-wrapper'>
      <div onClickCapture={handleOnSelect} id='viewerContainer' className='viewer-container'>
        <div id='viewer' className='pdfViewer pdf-container' />
      </div>
    </div>
  )
}
