import React, { useState } from 'react'
import * as Strings from 'common/strings'
import { CirclePicker } from 'react-color'

import { Anchor, Box, Form, Button, TextArea, Text } from 'grommet'

const NewHighlightView = ({ onDone, text, savedNoteText, id, savedColor }) => {
  const [noteText, setNoteText] = useState(savedNoteText || '')
  const [color, setColor] = useState(savedColor || '#FF6900')
  const truncate = (input) => input.length > 280 ? `${input.substring(0, 280)}...` : input

  return (
    <Box width='medium' background='white' round='small'>
      <Box
        style={{
          borderRadius: '8px',
          padding: '16px'
        }}
      >
        <Form onSubmit={() => onDone(noteText, color.hex || '#FF6900', id)}>
          <Box direction='column' gap='small' fill>
            <Text size='xsmall' margin={{ top: 'small' }} style={{ borderLeft: '3px solid #FD930D50', paddingLeft: '16px' }}>
              <i>{truncate(text)}</i>
            </Text>
            <TextArea
              style={{ height: '150px' }}
              resize={false}
              className='textField'
              value={noteText}
              placeholder={Strings.newHighlightPlaceholder}
              onChange={e => setNoteText(e.target.value)}
            />
            <Box alignSelf='center'>
              <CirclePicker circleSize={35} onChange={color => setColor(color)} color={color} triangle='hide' colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']} />
            </Box>
            <Button type='submit' primary label={Strings.saveHighlight} />
            <Anchor
              alignSelf='center'
              onClick={() => onDone(null, null, id)}
            >
              {Strings.cancel}
            </Anchor>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default NewHighlightView
