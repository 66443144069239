import { loadStripe } from '@stripe/stripe-js'
import firebase from 'firebase/app'

// LIVE KEY
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51J0s3DAwFQH9e1a517Sknq5jWOIZ0vJBruatklbQ6CUEYZiuNjuTajSAOiGfgj3eiGkyvmKamNqlsIib27gIitCP00txcoiAYH'

export async function invokeStripe (uid) {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
  const userDoc = await firebase.firestore()
    .collection('users')
    .doc(uid)
    .get()

  // The doc isn't created until a user first uses their free trial
  const trialUsed = userDoc.data().trialUsed
  const docRef = await firebase.firestore()
    .collection('customers')
    .doc(uid)
    .collection('checkout_sessions')
    .add({
      price: 'price_1K2mEtAwFQH9e1a5EJXnVIPk',
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      trial_from_plan: !trialUsed
    })

  docRef.onSnapshot(snap => {
    const { sessionId } = snap.data()
    if (sessionId) {
      stripe.redirectToCheckout({ sessionId })
    }
  })
}

export async function showStripePortal () {
  const functionRef = firebase
    .app()
    .functions('us-central1')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
  const { data } = await functionRef({ returnUrl: window.location.origin })
  window.location.assign(data.url)
}
