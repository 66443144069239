import React, { useState } from 'react'
import { Anchor, Box, TextInput, Text } from 'grommet'
import { Search } from 'grommet-icons'

import * as Strings from 'common/strings'

const SearchView = ({
  currentMatches,
  totalMatches,
  searchState,
  setBookSearchQuery,
  next,
  previous,
  cancel
}) => {
  const [query, setQuery] = useState('')

  return (
    <Box width='medium' pad='none' direction='column' justify='start' gap='small'>
      <TextInput
        className='textFieldFloating'
        placeholder={Strings.searchTextInBookPlaceholder}
        icon={<Search size='small' />}
        reverse
        value={query}
        onChange={event => {
          setQuery(event.target.value || '')
          setBookSearchQuery(event.target.value || '')
        }}
      />

      <Box
        width='medium' background='white' style={{
          borderRadius: '8px',
          padding: '16px'
        }}
        direction='row'
        gap='small'
      >
        <Text size='xsmall' style={{ flexGrow: 1 }}>
          {query === ''
            ? ''
            : (
                searchState === 1 ? 'Not Found' : (searchState === 0 || searchState === 2 ? `${totalMatches} matches` : '...')
              ) }
        </Text>
        <Anchor
          onClick={() => previous()}
        >
          {Strings.previous}
        </Anchor>
        <Anchor
          onClick={() => next()}
        >
          {Strings.next}
        </Anchor>
        <Anchor
          onClick={() => cancel()}
        >
          {Strings.cancel}
        </Anchor>
      </Box>

    </Box>
  )
}

export default SearchView
