import lodash from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import HighlightBox from './HighlightBox'

export const addHighlight = (highlights, scale, numPages) => {
  const groupedHighlights = lodash.groupBy(highlights, 'page')
  const pagesWithHighlights = []
  lodash.forEach(groupedHighlights, (item, key) => {
    pagesWithHighlights.push(parseInt(key))
    const highlightLayer = document.getElementById(`highlight-page-${key}`)
    if (highlightLayer) {
      ReactDOM.render(<HighlightBox items={item} scale={scale} />, highlightLayer)
    }
  })

  for (let i = 0; i < numPages; i++) {
    if (!pagesWithHighlights.includes(i)) {
      const highlightLayer = document.getElementById(`highlight-page-${i}`)
      if (highlightLayer) {
        ReactDOM.render(<HighlightBox items={[]} scale={scale} />, highlightLayer)
      }
    }
  }
}
