import React, { useContext, useState } from 'react'
import {
  Anchor,
  Box,
  Text,
  Spinner
} from 'grommet'
import UserContext from 'common/UserContext'
import * as Strings from 'common/strings'
import { invokeStripe } from 'common/stripeUtils'

import Navbar from 'common/Navbar'

import firebase from 'firebase'

const Premium = () => {
  const { userHasAccess, isPlanStatusAvailable, user } = useContext(UserContext)
  const showInactiveSubscriptionWarning = isPlanStatusAvailable && !userHasAccess
  const [waitingForStripe, setWaitingForStripe] = useState(false)

  firebase.analytics().logEvent('screen_view', {
    firebase_screen: 'Premium',
    firebase_screen_class: 'Premium'
  })

  return (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade'>
      <Navbar />
      <Box margin={{ top: '66px' }}>
        <Box
          flex={false}
          alignSelf='center'
          width='large'
          justify='center'
          style={{
            borderRadius: '8px',
            backgroundColor: 'white',
            padding: '16px',
            marginTop: '16px'
          }}
        >
          <Box direction='row' justify='between'>
            <Text size='medium'><b>{Strings.premium}</b></Text>
            {waitingForStripe && <Spinner size='xsmall' />}
          </Box>
          <Text size='small' margin={{ top: 'medium', bottom: 'small' }}>
            {Strings.inactiveSubscriptionWarningCardBody1} <b>{Strings.guarantee}</b> that we&apos;ll find whatever book you want in time for the Spring 2022 semester, if you&apos;re a premium subscriber.
            <br /><br />
            Your free account gives you access to our non-commercial titles. Premium subscribers get access to any titles they want. If we don&apos;t have digital rights for a premium title you want, we&apos;ll ship you a physical copy. Free shipping and free returns. We&apos;ll email you for your mailing address if this happens.
            <br /><br />
            Buckleaf Premium costs <b>$14.99 per month</b> for unlimited textbook access after a <b>one month free trial</b>.
            <br /><br />
            Pay per month and cancel anytime. No commitment required. If we don&apos;t fulfil your Spring semester requests on time, we&apos;ll refund all your money.
            <br /><br />
            Premium subscribers get premium customer support. Email us at <Anchor href='mailto:help@buckleaf.com'>help@buckleaf.com</Anchor> and we&apos;ll respond to you within 24 hours.
          </Text>

          <Text size='small'>
            <Anchor onClick={() => {
              window.open('https://buckleaf.paperform.co', '_blank')
            }}
            >
              {Strings.requestTitles}
            </Anchor>
            <Text> &middot; </Text>
            <Anchor onClick={() => {
              setWaitingForStripe(true)
              invokeStripe(user.uid)
            }}
            >
              {Strings.getPremium}
            </Anchor>
          </Text>
          <br />
          {showInactiveSubscriptionWarning ? <Text size='small'>You can fill this form <b>before</b> signing up for Buckleaf Premium! You&apos;ll only have to sign up after we confirm that we&apos;ve acquired the titles you want.</Text> : isPlanStatusAvailable && <Text size='small'><b>You already have Buckleaf Premium!</b></Text>}
        </Box>
      </Box>
    </Box>
  )
}

export default Premium
