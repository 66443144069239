import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './common/reportWebVitals'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import '@stripe/stripe-js'

import { Grommet } from 'grommet'

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from 'react-toasts'

import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/firebase-functions'

import './index.css'
import theme from './common/theme'

import Landing from 'Landing'
import Browse from 'Browse'
import Signup from 'Signup'
import Settings from 'Settings'

import ProtectedRoute from 'common/ProtectedRoute'
import { UserProvider } from 'common/UserContext'
import AboutBook from 'AboutBook'
import ReadBook from 'ReadBook'
import Search from 'Browse/Search'
import Premium from 'Premium'

const firebaseConfig = {
  apiKey: 'AIzaSyDrlpf0Z72M67jUzHFEPkHhs3ztm8MS1qA',
  authDomain: 'buckleaf-3145e.firebaseapp.com',
  projectId: 'buckleaf-3145e',
  storageBucket: 'buckleaf-3145e.appspot.com',
  messagingSenderId: '799536438108',
  appId: '1:799536438108:web:61ba3e06503cca141a7f99',
  measurementId: 'G-WHMHJ8SGLR'
}

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <UserProvider>
    <Grommet theme={theme} full>
      <Router>
        <Switch>
          <ProtectedRoute path='/browse'>
            <Browse />
          </ProtectedRoute>
          <ProtectedRoute path='/settings'>
            <Settings />
          </ProtectedRoute>
          <ProtectedRoute path='/premium'>
            <Premium />
          </ProtectedRoute>
          <Route path='/signup'>
            <Signup />
          </Route>
          <ProtectedRoute path='/read/:ISBN'>
            <ReadBook />
          </ProtectedRoute>
          <Route path='/book/:ISBN'>
            <AboutBook />
          </Route>
          <ProtectedRoute path='/search/:query'>
            <Search />
          </ProtectedRoute>
          <ProtectedRoute path='/' publicOnly>
            <Landing />
          </ProtectedRoute>
        </Switch>
      </Router>

      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.BOTTOM_CENTER}
      />
    </Grommet>
  </UserProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
