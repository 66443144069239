import React, { createContext, useState } from 'react'
import firebase from 'firebase/app'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoginAvailable, setIsLoginAvailable] = useState(false)
  const [isPlanStatusAvailable, setIsPlanStatusAvailable] = useState(false)

  // Whether their plan is active or trialing or neither
  const [planStatus, setPlanStatus] = useState('')
  const [userHasAccess, setUserHasAccess] = useState(false)
  const userState = {
    user,
    setUser,
    isLoggedIn,
    isLoginAvailable,
    planStatus,
    isPlanStatusAvailable,
    userHasAccess
  }

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      firebase.analytics().setUserId(user.uid)
      setIsLoggedIn(true)
      setUser(user)
    } else {
      setIsLoggedIn(false)
      setUser(null)
    }

    setIsLoginAvailable(true)
  })

  if (user) {
    firebase.firestore()
      .collection('customers')
      .doc(user.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(snapshot => {
        setIsPlanStatusAvailable(true)
        if (snapshot.empty) {
          setPlanStatus('')
          setUserHasAccess(false)
          return
        }

        setUserHasAccess(true)
        setPlanStatus(snapshot.docs[0].data().status)

        // Mark this user as having started their free trial to prevent abuse
        firebase.firestore()
          .collection('users')
          .doc(user.uid)
          .set({
            trialUsed: true
          }, { merge: true })
      })
  }

  return (
    <UserContext.Provider value={userState}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
