import React, { useState } from 'react'
import { Anchor, Box, Text } from 'grommet'
import * as Strings from 'common/strings'

const truncate = (input) => input.length > 280 ? `${input.substring(0, 280)}...` : input

const HighlightCard = ({ pageNumber, highlightedText, noteText, color, onEdit, onDelete, id }) => {
  const [isActive, setIsActive] = useState(false)
  const [isDeleteActive, setIsDeleteActive] = useState(false)

  return (
    <Box
      width='medium' background='white' style={{
        borderRadius: '8px',
        padding: '16px'
      }}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => {
        setIsActive(false)
        setIsDeleteActive(false)
      }}
    >
      <b>{Strings.highlightOnPage} {pageNumber}</b>
      <Text size='xsmall' margin={{ top: 'small' }} style={{ borderLeft: `3px solid ${color}`, paddingLeft: '16px' }}>
        <i>{truncate(highlightedText)}</i>
      </Text>
      <Text size='xsmall' margin={{ top: 'small' }}>
        {noteText}
      </Text>
      {isActive && (
        isDeleteActive
          ? (
            <Box width='medium' gap='small' direction='row' margin={{ top: 'small' }} align='center'>
              <Text size='xsmall'><b>Are you sure?</b></Text>
              <Anchor onClick={() => onDelete(id)}>
                <Text size='xsmall'><b>Yes</b></Text>
              </Anchor>
              <Anchor onClick={() => {
                setIsActive(false)
                setIsDeleteActive(false)
              }}>
                <Text size='xsmall'><b>No</b></Text>
              </Anchor>
            </Box>
            )
          : (
              <Box width='medium' gap='small' direction='row' margin={{ top: 'small' }}>
                <Anchor onClick={() => onEdit(id)}>
                  <Text size='xsmall'><b>Edit</b></Text>
                </Anchor>
                <Anchor onClick={() => setIsDeleteActive(true)}>
                  <Text size='xsmall'><b>Delete</b></Text>
                </Anchor>
              </Box>
            )
      )}
    </Box>
  )
}

export default HighlightCard
