import React, { useState, useContext } from 'react'
import UserContext from 'common/UserContext'

import {
  Box,
  Image,
  Text,
  Tip,
  Button
} from 'grommet'

import {
  Link
} from 'react-router-dom'

import { Add, Info, Catalog, Close } from 'grommet-icons'
import * as Strings from 'common/strings'

import firebase from 'firebase/app'

function addBookToUsersLibrary (book, user, callback) {
  firebase.firestore()
    .collection('books')
    .doc(book.ISBN)
    .update({
      users: firebase.firestore.FieldValue.arrayUnion(user.uid)
    })
    .then(() => {
      callback()
    })
}

function removeBookFromUsersLibrary (book, user, callback) {
  firebase.firestore()
    .collection('books')
    .doc(book.ISBN)
    .update({
      users: firebase.firestore.FieldValue.arrayRemove(user.uid)
    })
    .then(() => {
      callback()
    })
}

const BookCard = ({ book, isBookInUsersLibrary, onAddOrRemove }) => {
  const [beingHovered, setBeingHovered] = useState(false)
  const { user } = useContext(UserContext)

  const addBookToLibraryButton = (
    <Tip content='Add to my books'>
      <Button
        icon={<Add color='#FD930D' />}
        hoverIndicator
        alignSelf='center'
        style={{
          borderRadius: '100%',
          backgroundColor: 'white'
        }}
        onClick={() => {
          addBookToUsersLibrary(book, user, onAddOrRemove)
        }}
      />
    </Tip>
  )

  const removeBookFromLibraryButton = (
    <Tip content='Remove from my books'>
      <Button
        icon={<Close color='#FD930D' />}
        hoverIndicator
        alignSelf='center'
        style={{
          borderRadius: '100%',
          backgroundColor: 'white'
        }}
        onClick={() => {
          removeBookFromUsersLibrary(book, user, onAddOrRemove)
        }}
      />
    </Tip>
  )

  return (
    <Box
      direction='column' className='bookCard'
      flex={false}
      onMouseEnter={() => {
        setBeingHovered(true)
      }}
      onMouseLeave={() => {
        setBeingHovered(false)
      }}
    >
      <Image
        src={book.thumbnail}
        width='150px'
        height='232px'
        alignSelf='center'
      />
      <Text className='bookTitle' size='small'>{book.title}</Text>
      <Text className='bookAuthors' size='xsmall'>{book.authors}</Text>
      <Text size='xsmall'><i>{Strings.free}</i></Text>
      {beingHovered && (
        <Box
          direction='column'
          gap='medium' justify='center'
          className='hoverCardButtons'
        >
          <Box
            direction='column' gap='medium' justify='center'
          >
            <Link to={'/read/' + book.ISBN} align='center'>
              <Tip content='Read book'>
                <Button
                  icon={<Catalog color='#FD930D' />}
                  hoverIndicator
                  alignSelf='center'
                  style={{
                    borderRadius: '100%',
                    backgroundColor: 'white'
                  }}
                />
              </Tip>
            </Link>

            <Link to={'/book/' + book.ISBN} align='center'>
              <Tip content='About this book'>
                <Button
                  icon={<Info color='#FD930D' />}
                  hoverIndicator
                  alignSelf='center'
                  style={{
                    borderRadius: '100%',
                    backgroundColor: 'white'
                  }}
                />
              </Tip>
            </Link>

            {
              isBookInUsersLibrary ? removeBookFromLibraryButton : addBookToLibraryButton
            }
          </Box>
        </Box>)}
    </Box>
  )
}

export default BookCard
