const theme = {
  global: {
    colors: {
      brand: '#FD930D'
    },
    font: {
      family: 'Quicksand',
      size: '14px',
      height: '20px'
    }
  },
  custom: {
    iconSize: '17px'
  },
  tip: {
    content: {
      background: 'white'
    }
  }
}
export default theme
